import './aboutMe.scss';
import LaptopBackground from '../../../../imgs/laptopBackgrounf.jpg';
import ListSection from "../listSection/ListSection";
import WorkDone from "../workDone/WorkDone";

function AboutMe() {
    return (
        <div className="aboutMe">
            <img className="imgLaptopBackground" src={LaptopBackground}/>
            <div className="content">
                <div id="idAboutMeTitle" className="title">About Me</div>
                <div className="basicDescription">
                    <div className="left">
                        Hello, I'm Thinuwan, and I enjoy creating things for the web.
                        My interest in the software world started in 2013 with my engagement in higher education at <a href="https://www.sliit.lk/" target="_blank">SLIIT</a>. Throughout the journey, I worked for multiple software companies starting from <a href="https://www.ceylonlinux.com/" target="_blank">Ceylon Linux</a>, which is a startup providing web apps for local business companies, where I worked as an intern in PHP development, and then <a href="https://www.pearson.com/" target="_blank">Pearson</a>, which is a large scale global company providing software products for education domain where I worked five years developing number of android and web apps.
                        <br/><br/>
                        Currently, I'm working in <a href="https://cakeengineering.lk/" target="_blank">Cake Engineering</a> which is connected with <a href="https://madmobile.com/" target="_blank">Mad Mobile</a>, where I'm a lead in React based front end development for the restaurant products.
                    </div>
                    <div className="right">
                        Apart from those, I'm thrilled about having products developed on my own, and the most loving one is <a href="https://play.google.com/store/apps/details?id=com.thinugigs.drawingbook" target="_blank">Drawing Book</a>, an android app that I published a few years ago, which has around 200k+ downloads.
                        <br/><br/>
                        I'm a freelancer too. It provides a good opportunity to get hands dirty in various areas while having something for the pocket. The recent one I worked on is the <a href="https://hnb.net/" target="_blank">HNB Bank</a> official site.
                        <br/><br/>
                        Fast forward to today, my passion is exploring new things in the tech world while improving my career, experience, knowledge, and potential, and getting expertise in ReactJs based web areas.
                    </div>
                </div>
            </div>
            <ListSection/>
            <div className="technologies">
                <div className="titleContainer">
                    <span className="left"><hr/></span>
                    <div className="title">Technologies I'm familiar with</div>
                    <span className="right"><hr/></span>
                </div>
                <div className="container">
                    <div>ReactJs, HTML, Javascript, Css and other web related technologies</div>
                    <div>Android, Kotlin</div>
                    <div>NodeJs</div>
                    <div>AWS, Google services, Firebase services, Git, Databases</div>
                </div>
            </div>
            <div className="technologies">
                <div className="titleContainer">
                    <span className="left"><hr/></span>
                    <span className="middle title">Certifications and Achievements</span>
                    <span className="right"><hr/></span>
                </div>
                <div className="container normal">
                    <div>AWS Certified Developer - Associate | Amazon Web Services (AWS)  &nbsp;
                        <a className="company" href="https://www.credly.com/badges/38f88756-25a9-437c-9ace-dff6f7378c9d?source=linked_in_profile" target="_blank">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                    <div>IEEE Publication (NBQSA 1st round selection - 2017) &nbsp;
                        <a className="company" href="https://ieeexplore.ieee.org/document/8294109" target="_blank">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                    <div>LinkedIn Skill Assessments Badges &nbsp;
                        <a className="company" href="https://www.linkedin.com/in/thinuwan/" target="_blank">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                </div>
            </div>
            <WorkDone/>
        </div>
    )
}

export default AboutMe;

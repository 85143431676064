import './headerDesktop.scss';

function HeaderDesktop(props) {
    const loadLinkedIn = () => {
        window.open(
            "https://www.linkedin.com/in/thinuwan/", "_blank");
    }
    const loadFacebook = () => {
        window.open(
            "https://www.facebook.com/thinuwan.wickramaarachchi", "_blank");
    }
    const loadEmail = () => {
        window.open(
            "mailto: thinugigs@gmail.com", "_blank");
    }
    const scrollToAboutMe = () => {
        document.getElementById("idAboutMeTitle").scrollIntoView({
            behavior: 'smooth'
        });
    }
    const scrollToMyProjects = () => {
        document.getElementById("idWorkDoneTitle").scrollIntoView({
            behavior: 'smooth'
        });
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div className="headerDesktop">
            <div id="headerContent" className="content">
                <div className="logo" onClick={scrollToTop} style={{cursor: 'pointer'}}>Thinu Gigs</div>
                <div className="menus">
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={scrollToAboutMe}>About Me</div>
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={scrollToMyProjects}>My Projects</div>
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={() => props.setIsContactShowing(true)}>Contact Me</div>
                </div>
                <div className="links">
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadLinkedIn}><i className="icon fa-brands fa-linkedin fa-xl"></i></div>
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadFacebook}><i className="icon fa-brands fa-facebook-square fa-xl"></i></div>
                    <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadEmail}><i className="icon fa-solid fa-envelope-open-text fa-xl"></i></div>
                </div>
            </div>
        </div>
    )
}

export default HeaderDesktop;

import './listSection.scss';

function ListSection() {
    return (
        <div className="listSection">
            <div className="title">My Work Experience</div>
            <div className="container">
                <div className="row">
                    <div className="left">
                        <div className="item item1">
                            <div><span className="position">Associate Lead @ </span><a className="company" href="https://cakeengineering.lk/" target="_blank">Cake Engineering</a></div>
                            <div className="duration">Feb 2022 - Present</div>
                            <div className="workList">
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Working in the design and development of front-end components of the end-to-end POS product for restaurants, retailers, and grocers globally</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Working collaboratively in cross-functional teams, providing solutions, troubleshooting, and recovering live issues</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Adhering and fine-tuning DevOps operations from development, QA, and deployment, to post-production support and evolving engineering best practices</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Conducting code reviews, optimizations, and performance and analytical improvements for the existing components</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                    </div>
                    <div className="right">
                        <div className="item item2">
                            <div><span className="position">Freelancer @ </span><a className="company" href="https://affno.com/" target="_blank">Affno Asia Pacific</a></div>
                            <div className="duration">Jul 2021 - Present</div>
                            <div className="workList">
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="icon">&#9758;</div>
                                    <div className="content">Rewriting the existing HNB Bank CMS with totally new UIs using ReactJs from the scratch</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="icon">&#9758;</div>
                                    <div className="content">Working as the sole front-end developer in all areas from UI designing, prototyping, developing, and responsibly delivering the project.</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="icon">&#9758;</div>
                                    <div className="content">Working in progress with the feature improvements and bug fixes on a part time basis.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                        <div className="item item1">
                            <div><span className="position">Senior Software Engineer, SE, ASE @ </span><a className="company" href="https://www.pearson.com/" target="_blank">Pearson</a></div>
                            <div className="duration">Jan 2017 - Feb 2022</div>
                            <div className="workList">
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">One of the core developers of creating and maintaining ReactJs reusable components which are provided to use in UI and functional level of other react based end products</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Conducting research and building POC outputs in both web and mobile(android) for the novelty ideas coming from the future roadmaps defined by product owners</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Rewriting the content authoring tool with the new UIs and with new the technologies</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="content">Coming up with innovative ideas and building the applications for in-house software competitions, mentoring juniors, being a member of interview panels, and number of extra activities out of the work on the table</div>
                                    <div className="icon">&#9756;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                    </div>
                    <div className="right">
                        <div className="item item2">
                            <div><span className="position">Intern @ </span><a className="company" href="https://www.ceylonlinux.com/" target="_blank">Ceylon Linux</a></div>
                            <div className="duration">May 2016 - Oct 2016</div>
                            <div className="workList">
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="icon">&#9758;</div>
                                    <div className="content">Starting the career with the internship. Worked in PHP development of the products provided for in-country retail businesses</div>
                                </div>
                                <div className="workItem">
                                    <div className="icon-mobile">&#9758;</div>
                                    <div className="icon">&#9758;</div>
                                    <div className="content">Got the opportunity to put hands on industry level android development with a location tracking app</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListSection;

import Header from "./components/common/header/Header";
import Profile from "./components/home/profileSegment/profile/Profile";
import AboutMe from "./components/home/aboutMeSegment/aboutMe/AboutMe";
import ContactMe from "./components/home/contactMeSegment/contactMe";
import Copyright from "./components/home/copyright/Copyright";
import {useEffect, useState} from "react";

function App() {
    const [isContactMeShowing, setIsContactShowing] = useState(false);
    return (
        <div>
            <Header setIsContactShowing={setIsContactShowing}/>
            <Profile/>
            <AboutMe/>
            {isContactMeShowing && <ContactMe setIsContactShowing={setIsContactShowing}/>}
            <Copyright/>
        </div>
    );
}

export default App;

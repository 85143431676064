import './contactMe.scss';
import {useState} from "react";

function ContactMe(props) {

    const [isUnloading, setIsUnloading] = useState(false);

    const unloadContactMe = () => {
        setIsUnloading(true);
        setTimeout(() => {
            props.setIsContactShowing(false)
        }, 450)
    }
    return (
        <div className={`contactMe ${isUnloading && 'contactMeUnload'}`}>
            <div className="closeImgContainer">
                <i className="closeImg fa-solid fa-xmark" onClick={unloadContactMe}></i>
            </div>
            <div className="header">
                Contact Me
            </div>
            <div className="wrapper">
                <div className="contactList">
                    <div className="item">
                        <i className="img icon fa-brands fa-linkedin fa-xl"></i>
                        <div>
                            <a  className="text" href="https://www.linkedin.com/in/thinuwan/" target="_blank">LinkedIn</a>
                        </div>
                    </div>
                    <div className="item">
                        <i className="img icon fa-brands fa-facebook-square fa-xl"></i>
                        <div>
                            <a className="text" href="https://www.facebook.com/thinuwan.wickramaarachchi" target="_blank">Facebook</a>
                        </div>
                    </div>
                    <div className="item">
                        <i className="img icon fa-brands fa-whatsapp fa-xl"></i>
                        <div>
                            <a className="text" href="whatsapp://send?abid=0712621232" target="_blank">+94 71 26 21 23 2</a>
                        </div>
                    </div>
                    <div className="item">
                        <i className="img icon fa-solid fa-envelope-open-text fa-xl"></i>
                        <div>
                            <a className="text" href = "mailto: thinugigs@gmail.com" target="_blank">thinugigs@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactMe;

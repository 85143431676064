import HeaderDesktop from "./headerDesktop/HeaderDesktop";
import HeaderMobile from "./headerMobile/HeaderMobile";
import {useEffect, useState} from "react";

function Header(props) {

    const sizeofScreenSeparate = 1300;
    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isRed, setIsRed] = useState(true);

    useEffect(() => {
        addWindowResizeEvent();
        addWindowScrollEvent();
    }, [])

    const addWindowResizeEvent = () => {
        setRelevantScreenType();
        setRelevantColors();
        window.addEventListener('resize', function(event) {
            setRelevantScreenType();
            setRelevantColors();
        }, true);
    };

    const addWindowScrollEvent = () => {
        setRelevantColors();
        document.addEventListener('scroll', function(e) {
            setRelevantColors();
        });
    };

    const setRelevantScreenType = () => {
        if(window.innerWidth > sizeofScreenSeparate) {
            setIsMobileSize(false);
        } else {
            setIsMobileSize(true);
        }
    };

    const setRelevantColors = () => {
        const rect = document.getElementsByClassName('aboutMe')[0].getBoundingClientRect();
        if(window.scrollY + 50 > window.scrollY + rect.top) {
            document.getElementById('headerContent').style.color = "#0a192f";
            setIsRed(false);
        } else {
            document.getElementById('headerContent').style.color = "#cc3533";
            setIsRed(true);
        }
    };

    return (
        <div>
            {
                isMobileSize ?
                    <HeaderMobile
                        setIsContactShowing={props.setIsContactShowing}
                        isRed={isRed}
                    />
                    :
                    <HeaderDesktop
                        setIsContactShowing={props.setIsContactShowing}
                        isRed={isRed}
                    />
            }
        </div>
    )
}

export default Header;

import './headerMobile.scss';
import {useState} from "react";

function HeaderMobile(props) {

    const [isExpanded, setIsExpanded] = useState(false);

    const openContactMe = () => {
        setIsExpanded(false);
        props.setIsContactShowing(true);
    }

    const loadLinkedIn = () => {
        window.open(
            "https://www.linkedin.com/in/thinuwan/", "_blank");
        setIsExpanded(false);
    }
    const loadFacebook = () => {
        window.open(
            "https://www.facebook.com/thinuwan.wickramaarachchi", "_blank");
        setIsExpanded(false);
    }
    const loadEmail = () => {
        window.open(
            "mailto: thinugigs@gmail.com", "_blank");
        setIsExpanded(false);
    }

    const scrollToAboutMe = () => {
        document.getElementById("idAboutMeTitle").scrollIntoView({
            behavior: 'smooth'
        });
        setIsExpanded(false);
    }
    const scrollToMyProjects = () => {
        document.getElementById("idWorkDoneTitle").scrollIntoView({
            behavior: 'smooth'
        });
        setIsExpanded(false);
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setIsExpanded(false);
    }

    return (
        <div className="headerMobile">
            <div id="headerContent" className="content">
                <div className="logo">
                    <div className="logoText" onClick={scrollToTop} style={{cursor: 'pointer'}}>Thinu Gigs</div>
                    <div className="menuIcon"
                    onClick={() => setIsExpanded(!isExpanded)}>
                        { isExpanded ? <i className="fa-solid fa-xmark"></i> : <i className="fa-solid fa-bars"></i> }
                    </div>
                </div>
                {isExpanded &&
                <>
                    <div className="menus">
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={scrollToAboutMe}>About Me</div>
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={scrollToMyProjects}>My Projects</div>
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={openContactMe}>Contact Me</div>
                    </div>
                    <div className="links">
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadLinkedIn}><i className="icon fa-brands fa-linkedin fa-xl"></i></div>
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadFacebook}><i className="icon fa-brands fa-facebook-square fa-xl"></i></div>
                        <div className={`item ${!props.isRed && 'itemBlue'}`} onClick={loadEmail}><i className="icon fa-solid fa-envelope-open-text fa-xl"></i></div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default HeaderMobile;

import './video.scss';
import {useEffect} from "react";

function Video() {

    useEffect(() => {
        configureVideoHeight();
    })

    const configureVideoHeight = () => {
        setVideoHeight();
        window.addEventListener('resize', function(event) {
            setVideoHeight()
        }, true);
    };

    const setVideoHeight = () => {
        const height = document.getElementsByClassName('profile')[0].clientHeight;
        document.getElementsByClassName('video')[0].style.height = height+'px';
    }

    return (
        <div className="video">
            <video className="video-play" autoPlay muted loop playsInline>
                <source src="https://thinugigs-portfolio.s3.ap-south-1.amazonaws.com/profileVideo2.mp4" type="video/mp4"/>
            </video>
        </div>
    )
}

export default Video;

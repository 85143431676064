import './profile.scss';
import ProfileImg from '../../../../imgs/profileImg.jpg';
import Video from "../video/Video";

function Profile() {
    return (
        <div className="profile">
            <Video/>
            <div className="container">
                <div className="imgCardContainer">
                    <img className="img1" src={ProfileImg}/>
                    <img className="img2" src={ProfileImg}/>
                </div>
                <div className="description">
                    <div className="content1">Hello, Welcome to <span>thinugigs.com</span><br/> I'm <span>Thinuwan <i className="fa-solid fa-face-smile-wink fa-sm"></i></span></div>
                    <div className="content2">I'm working as a professional web developer and here is my portfolio.</div>
                    <div className="content3">I've spent the past 6+ years working on the software industry across different areas of front-end development.
                        Currently I'm contributing as a lead for a global company mainly on ReactJs based web areas.</div>
                    <div className="content3">Out of the work you’ll find me chilling around, So happy to hear from you guys <i className="fa-solid fa-champagne-glasses"></i></div>
                </div>
            </div>
        </div>
    );
}

export default Profile;

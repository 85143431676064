import './workDone.scss';
import ImgMyWork1 from '../../../../imgs/myWork1.png';

function WorkDone() {
    return (
        <div id="idWorkDoneTitle" className="workDone">
            <div className="title">Things I've Built</div>
            <div className="workItemContainer">
                <div className="item-description">
                    <div className="title">Drawing Book</div>
                    <div className="description">
                        <div className="text1">Android app in Play Store</div>
                        <br/>
                        <div className="text2">Try your artistic talents of drawing faster and easier than ever. Just draw whats in your mind and save it in the album or send it to anyone via any media</div>
                    </div>
                    <a className="button" href="https://play.google.com/store/apps/details?id=com.thinugigs.drawingbook" target="_blank">View app</a>
                </div>
                <div className="item-images">
                    <img className="img" src={ImgMyWork1}/>
                </div>
            </div>
        </div>
    )
}

export default WorkDone;

import './copyright.scss';

function Copyright() {
    return (
        <div className="copyright">
            Copyright © 2022 Thinuwan Wickramaarachchi. All rights reserved.
        </div>
    );
}

export default Copyright;
